define("discourse/plugins/long-updated-at/discourse/helpers/custom_date", ["exports", "discourse-common/lib/helpers", "@ember/template"], function (_exports, _helpers, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = customDate;
  (0, _helpers.registerRawHelper)("custom-date", customDate);
  function customDate(date) {
    let formatted_data = moment(date).format(I18n.t("dates.tiny.date_month"));
    return (0, _template.htmlSafe)("<span class='relative-date' data-time='" + date.getTime() + "'>" + formatted_data + "</span>");
  }
});